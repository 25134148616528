<template>
<div id="SideMenu">

  <div id="conversationMenu">

    <div id="conversationMenu__chat">

    </div>
  </div>

</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'SideMenu',
  setup () {

    onMounted(() => {

      let conversationMenu = document.getElementById('conversationMenu');

      conversationMenu.onpointerdown = () => {
        conversationMenu && conversationMenu.classList.toggle('activeMenu');
      };


    });


  },
};
</script>

<style lang="stylus" scoped>
#SideMenu
  background-color #8b54f0;
  background-image: radial-gradient(circle at top left, #67b26f, transparent 60%),
    radial-gradient(circle at top right, #4ca2cd, transparent 60%);
  border-radius 20px
  position absolute
  top 70px
  right 70px
  bottom 70px
  left 70px
  #conversationMenu
    position absolute
    height 50px
    width 50px
    bottom 20px
    right 20px
    background red
    padding 20px
    transition all 500ms cubic-bezier(0.6, 0, 0.2, 1);
    border-radius 50%
    &.activeMenu
      height 90%
      width 300px
      border-radius 20px
    #conversationMenu__chat
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      background goldenrod

</style>